import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

const ApplicationForm = ({
  categoryOptions,
  formValues,
  setValue,
  onSubmit,
  errors,
}) => {
  const handleTypeaheadChange = (selected) => {
    console.log('debug typeahead', selected);
    if (typeof selected[0] === 'string') {
      setValue('category', `${selected[0]}`);
    } else if (typeof selected[0] === 'object' && selected[0] !== null) {
      setValue('category', `${selected[0].label}`);
    } else {
      setValue('category', '');
    }
  };

  return (
    <form onSubmit={onSubmit}>
      {errors && (
        <div className='alert alert-danger'>
          <b>Es gab Fehler beim Speichern:</b>
          <ul>
            {errors.map((err, index) => (
              <li key={index}>{err}</li>
            ))}
          </ul>
        </div>
      )}
      <div className='row g-3 mb-3'>
        <div className='col-3'>
          <label htmlFor='title' className='form-label'>
            Titel
          </label>
          <input
            type='text'
            id='title'
            className='form-control'
            value={formValues.title ?? ''}
            onChange={(e) => setValue('title', e.target.value)}
          />
        </div>
        <div className='col-6'>
          <label htmlFor='description' className='form-label'>
            Bezeichnung
          </label>
          <input
            type='text'
            id='description'
            className='form-control'
            value={formValues.description ?? ''}
            onChange={(e) => setValue('description', e.target.value)}
          />
        </div>
        <div className='col-3'>
          <label htmlFor='issuer' className='form-label'>
            Antragsteller:in
          </label>
          <input
            type='text'
            id='issuer'
            className='form-control'
            value={formValues.issuer ?? ''}
            onChange={(e) => setValue('issuer', e.target.value)}
          />
        </div>
      </div>
      <div className='row g-3 mb-3'>
        <div className='col-3'>
          <label htmlFor='date' className='form-label'>
            Datum
          </label>
          <input
            type='date'
            id='date'
            className='form-control'
            value={formValues.date ?? ''}
            onChange={(e) => setValue('date', e.target.value)}
          />
        </div>
        <div className='col-3 align-self-end'>
          <label htmlFor='amount' className='form-label'>
            Betrag
          </label>
          <div className='input-group'>
            <input
              type='number'
              id='amount'
              className='form-control'
              placeholder='Betrag'
              min={0}
              step={0.01}
              value={formValues.amount ?? ''}
              onChange={(e) => setValue('amount', e.target.value)}
            />
            <span className='input-group-text'>€</span>
          </div>
        </div>
        <div className='col-6'>
          <label htmlFor='category' className='form-label'>
            Kategorie
          </label>
          <Typeahead
            id='category'
            options={categoryOptions}
            onChange={handleTypeaheadChange}
            allowNew={true}
            newSelectionPrefix={'Neue Kategorie: '}
          />
        </div>
      </div>
    </form>
  );
};

export default ApplicationForm;
