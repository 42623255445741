import React, { FormEvent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useCreateFiscalYearMutation } from '../../api/fiscalYearApi';

const AddFiscalYear = () => {
  const [createFiscalYear, { isLoading }] = useCreateFiscalYearMutation();
  const [errors, setErrors] = useState(null);
  const [open, setOpen] = useState(false);

  const defaultFormValues = {
    year: '',
    initialBudget: '0',
    initialPlenaAmount: '24',
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const setValue = (prop, value) => {
    setFormValues((v) => ({
      ...v,
      [prop]: value,
    }));
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const handleOpen = () => {
    setErrors(null);
    setFormValues(defaultFormValues);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setErrors(null);
    // console.log(formValues);

    const year = parseInt(formValues.year, 10);
    if (isNaN(year) || year < 1900 || year > 2100) {
      setErrors(
        'Bitte geben Sie ein gültiges Jahr zwischen 1900 und 2100 ein.'
      );
      return;
    }

    createFiscalYear({
      ...formValues,
      year: `${year}-01-01T00:00:00.000Z`,
      initialBudget: parseFloat(formValues.initialBudget),
      initialPlenaAmount: parseInt(formValues.initialPlenaAmount),
    })
      .unwrap()
      .then(() => {
        toast.success('Haushaltsjahr erfolgreich gestartet.');
        handleClose();
      })
      .catch((error) => {
        setErrors(error?.data?.message);
      });
  };

  return (
    <>
      <button className='btn btn-lg px-5 btn-primary mt-3' onClick={handleOpen}>
        Neues Haushaltsjahr starten
      </button>
      <Modal size='lg' show={open} backdrop='static' onHide={handleClose}>
        <Modal.Header>
          <h4>Haushaltsjahr starten</h4>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            {errors && (
              <div className='alert alert-danger'>
                <b>Es gab einen Fehler beim Speichern:</b>
                <br />
                {errors}
              </div>
            )}
            <div className='row g-3 mb-3'>
              <div className='col-3'>
                <label htmlFor='year' className='form-label'>
                  Zu startendes Haushaltsjahr (4 stellig):
                </label>
                <input
                  type='number'
                  id='year'
                  className='form-control'
                  min={1900}
                  max={2100}
                  step={1}
                  pattern='[0-9]{4}'
                  value={formValues.year ?? ''}
                  onChange={(e) => setValue('year', e.target.value)}
                />
              </div>
            </div>
            <div className='row g-3 mb-3'>
              <div className='col-3'>
                <label htmlFor='initialBudget' className='form-label'>
                  Initiales Budget
                </label>
                <div className='input-group'>
                  <input
                    type='number'
                    id='initialBudget'
                    className='form-control'
                    placeholder='Start Saldo'
                    min={0}
                    step={0.01}
                    value={formValues.initialBudget ?? 0}
                    onChange={(e) => setValue('initialBudget', e.target.value)}
                  />
                  <span className='input-group-text'>€</span>
                </div>
              </div>
              <div className='col-3'>
                <label htmlFor='initialPlenaAmount' className='form-label'>
                  Anzahl Plena
                </label>
                <div className='input-group'>
                  <input
                    type='number'
                    id='initialPlenaAmount'
                    className='form-control'
                    placeholder='Start Saldo'
                    min={0}
                    step={1}
                    value={formValues.initialPlenaAmount ?? 0}
                    onChange={(e) =>
                      setValue('initialPlenaAmount', e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={handleClose}
          >
            Abbrechen
          </button>
          <button
            type='submit'
            className='btn btn-success'
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Speichern
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddFiscalYear;
