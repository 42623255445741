import React from 'react';

const AccountForm = ({
  formValues,
  setValue,
  onSubmit,
  errors,
  handleClose,
}) => {
  return (
    <form onSubmit={onSubmit}>
      {errors && (
        <div className='alert alert-danger'>
          <b>Es gab Fehler beim Speichern:</b>
          <ul>
            {errors.map((err, index) => (
              <li key={index}>{err}</li>
            ))}
          </ul>
        </div>
      )}
      <div className='row g-3 mb-3'>
        <div className='col-3'>
          <label htmlFor='name' className='form-label'>
            Kontoname
          </label>
          <input
            type='text'
            id='name'
            className='form-control'
            value={formValues.name ?? ''}
            onChange={(e) => setValue('name', e.target.value)}
          />
        </div>
        <div className='col-3'>
          <label htmlFor='startBalance' className='form-label'>
            Saldo zum Start
          </label>
          <div className='input-group'>
            <input
              type='number'
              id='startBalance'
              className='form-control'
              placeholder='Start Saldo'
              min={0}
              step={0.01}
              value={formValues.startBalance ?? 0}
              onChange={(e) => setValue('startBalance', e.target.value)}
            />
            <span className='input-group-text'>€</span>
          </div>
        </div>
        <div className='col-3'>
          <label className='form-label' htmlFor='isManual'>
            Manuelles Konto
          </label>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              id='isManual'
              value={formValues.isManual ?? false}
              onChange={(e) => setValue('isManual', e.target.checked)}
            />
            <p className={`tx-${formValues.isManual ? 'success' : 'danger'}`}>
              {formValues.isManual ? 'aktiviert' : 'deaktiviert'}
            </p>
          </div>
        </div>
      </div>
      {formValues.isManual && (
        <div className='ow g-3 mb-3'>
          <div className='col-5'>
            <label htmlFor='comment' className='form-label'>
              Hinweis zum Konto
            </label>
            <input
              type='text'
              id='comment'
              className='form-control'
              value={formValues.accountComment ?? ''}
              onChange={(e) => setValue('accountComment', e.target.value)}
            />
          </div>
        </div>
      )}
    </form>
  );
};

export default AccountForm;
