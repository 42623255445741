import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { setFiscalYear } from '../../redux/slices/authSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Modal, NavItem } from 'react-bootstrap';
import { useListFiscalYearsQuery } from '../../api/fiscalYearApi';
import { displayYear } from '../../utils/date';
import { useNavigate } from 'react-router-dom';

const FiscalYearBox = () => {
  const { fiscalYear } = useSelector((state: RootState) => state.auth);
  const { data: fiscalYearList, isLoading } = useListFiscalYearsQuery();
  const dispatch: AppDispatch = useDispatch();
  const [sortedFYL, setSortedFYL] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [open, setOpen] = useState(false);
  const [noMatchingYear, setNoMatchingYear] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (fiscalYearList) {
      const sortedCopy = [...fiscalYearList].sort((a, b) => {
        const dateA = new Date(a.year);
        const dateB = new Date(b.year);
        return dateB.getTime() - dateA.getTime();
      });
      setSortedFYL(sortedCopy);
      setSelectedYear(sortedCopy[0]?.id);
    }
  }, [fiscalYearList]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      setFiscalYear({
        fiscalYear: fiscalYearList.find((year) => year.id === selectedYear),
      })
    );
    setOpen(false);
  };

  const handleClick = () => {
    navigate('/fiscal-year');
    setOpen(false);
  };

  useEffect(() => {
    if (fiscalYearList && fiscalYear === null) {
      const currentYear = new Date().getFullYear();
      const matchingYear = fiscalYearList.find(
        (fy) => displayYear(fy.year) === currentYear.toString()
      );
      if (matchingYear) {
        setNoMatchingYear(false);
        dispatch(setFiscalYear({ fiscalYear: matchingYear }));
      } else {
        setOpen(true);
        setNoMatchingYear(true);
      }
    }
  }, [fiscalYearList, fiscalYear]);

  return (
    <>
      <NavItem>
        <a
          className='btn-link nav-link'
          role='button'
          onClick={() => setOpen(true)}
        >
          <span className='fy-text'>
            {fiscalYear ? displayYear(fiscalYear?.year) : 'Haushaltsjahr?'}{' '}
            &nbsp;
          </span>
          <span className='fy-text-hover'>ändern &nbsp; </span>
          <FontAwesomeIcon icon={faCalendar} className='fy-icon' />
          <FontAwesomeIcon icon={faPenToSquare} className='fy-icon-hover' />
        </a>
      </NavItem>
      <Modal show={open} backdrop='static' keyboard={false}>
        <Modal.Header>
          <Modal.Title>Haushaltsjahr ändern</Modal.Title>
        </Modal.Header>
        <form onSubmit={(event) => handleSubmit(event)}>
          <Modal.Body>
            {noMatchingYear && (
              <div className='alert alert-danger mb-3'>
                <b>
                  Für das Tagesdatum wurde kein passendes Haushaltsjahr
                  gefunden. Bitte Auswählen oder im Dashboard ein neues
                  Haushaltsjahr starten.
                </b>
              </div>
            )}
            <button
              type='button'
              className='btn btn-primary mb-3'
              onClick={handleClick}
            >
              Zum Haushaltsjahr Dashboard
            </button>
            <div className='form-group mb-3'>
              <label htmlFor='year' className='form-label'>
                Haushaltsjahr festlegen
              </label>
              <select
                className='form-control'
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                disabled={isLoading || fiscalYearList?.length < 1}
              >
                {sortedFYL?.map((fY, index) => (
                  <option key={index} value={fY.id}>
                    {displayYear(fY.year)}
                  </option>
                ))}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={() => setOpen(false)}
            >
              Abbrechen
            </button>
            <button type='submit' className='btn btn-primary'>
              Speichern
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default FiscalYearBox;
