import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import impressumMd from './../../assets/md/impressum.md';

const SiteNoticePage = () => {
  const [siteNoticeText, setSiteNoticeText] = useState(null);

  useEffect(() => {
    if (!siteNoticeText) {
      fetch(impressumMd)
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          setSiteNoticeText(text);
        });
    }
  }, []);

  return (
    <div>
      <div className='card text-start'>
        <div className='card-body'>
          <p className='card-text'>
            <ReactMarkdown children={siteNoticeText} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default SiteNoticePage;
