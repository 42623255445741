import { displayYear } from '../../utils/date';
import { useListFiscalYearsQuery } from '../../api/fiscalYearApi';
import AddFiscalYear from './AddFiscalYear';

const FiscalYearDashboard = () => {
  const { data: fiscalYearList } = useListFiscalYearsQuery();

  return (
    <>
      <h1>Haushaltsjahr Dashboard</h1>
      <div className='text-start'>
        <p>Es gibt aktuell folgende Haushaltsjahre:</p>
        <ul className='list-group'>
          {fiscalYearList?.map((year) => (
            <li className='list-group-item'>
              {displayYear(year.year)} - Status: {year.status} - Plena:{' '}
              {year.initialPlenaAmount} - Budget: {year.initialBudget} €
            </li>
          ))}
        </ul>
        <AddFiscalYear />
      </div>
    </>
  );
};

export default FiscalYearDashboard;
