import { fubApi } from './index';

const applicationApi = fubApi.injectEndpoints({
  endpoints: (builder) => ({
    listApplications: builder.query<any, void>({
      query: () => `/api/v1/application`,
      providesTags: [{ type: 'ApplicationList', id: 'LIST' }],
    }),
    listApplicationCategories: builder.query<any, void>({
      query: () => `/api/v1/application/application-category`,
      providesTags: [{ type: 'ApplicationCategoryList', id: 'LIST' }],
    }),
    getApplication: builder.query<any, void>({
      query: (id) => `/api/v1/application/${id}`,
      providesTags: (result) => [{ type: 'Application', id: result.id }],
    }),
    createApplication: builder.mutation<any, any>({
      query: (payload) => ({
        url: `api/v1/application`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        { type: 'ApplicationList', id: 'LIST' },
        { type: 'ApplicationCategoryList', id: 'LIST' },
      ],
    }),
    updateApplication: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `api/v1/application/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'ApplicationList', id: 'LIST' }],
    }),
  }),
});

export const {
  useListApplicationsQuery,
  useListApplicationCategoriesQuery,
  useGetApplicationQuery,
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
} = applicationApi;
