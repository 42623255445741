import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const fubApi = createApi({
  reducerPath: 'fubApi',
  baseQuery,
  endpoints: () => ({}),
  tagTypes: [
    'AccountList',
    'InventoryList',
    'ApplicationList',
    'ApplicationCategoryList',
    'Application',
    'FiscalYearList',
  ],
});
