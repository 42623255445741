import Table from '../../components/table/Table';
import { useListApplicationsQuery } from '../../api/applicationApi';
import { displayYear, formatDateLong } from '../../utils/date';
import AddApplication from './AddApplication';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useEffect, useState } from 'react';

const Applications = () => {
  const { data: applicationList, isLoading } = useListApplicationsQuery();
  const { fiscalYear } = useSelector((state: RootState) => state.auth);
  const [filteredApplications, setFilteredApplications] = useState([]);

  useEffect(() => {
    console.log(fiscalYear);
    console.log(applicationList);
    if (applicationList && fiscalYear) {
      setFilteredApplications(
        applicationList.filter((appl) => appl.fiscalYear?.id === fiscalYear?.id)
      );
    }
  }, [applicationList, fiscalYear]);

  const columns = [
    {
      header: 'Datum',
      accessorKey: 'date',
      cell: (cell: { row: any }) => (
        <>{formatDateLong(cell.row.original.date)}</>
      ),
    },
    {
      header: 'Kategorie',
      accessorKey: 'category.name',
    },
    {
      header: 'Bezeichnung',
      accessorKey: 'description',
    },
    {
      header: 'Status',
      accessorKey: 'status',
    },
    {
      header: 'Betrag',
      accessorKey: 'amount',
      cell: (cell: { row: any }) => <>{cell.row.original.amount} €</>,
    },
  ];

  return !isLoading && applicationList ? (
    <>
      <div>
        <Table
          data={filteredApplications}
          columns={columns}
          export={true}
          noDataText='Keine Anträge gefunden.'
          disabled={false}
          isSearchable={true}
          headerContent={<AddApplication fiscalYear={fiscalYear} />}
          headline={`Anträge ${displayYear(fiscalYear?.year)}`}
        />
      </div>
    </>
  ) : (
    <div>Anträge werden geladen</div>
  );
};

export default Applications;
