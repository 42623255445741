import Table from '../../components/table/Table';
import AddAccount from './AddAccount';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFilterAccountsQuery } from '../../api/accountApi';

const Accounts = () => {
  const { fiscalYear } = useSelector((state: RootState) => state.auth);
  const { data: accountList, isLoading } = useFilterAccountsQuery({
    fiscalYear: fiscalYear?.id ?? skipToken,
  });

  const columns = [
    {
      header: 'Kontoname',
      accessorKey: 'name',
    },
    {
      header: 'Konto-ID',
      accessorKey: 'accountNumber',
    },
    {
      header: 'Kontotyp',
      accessorKey: 'isManual',
      cell: (cell: { row: any }) => (
        <>{cell.row.original.isManual ? 'manuell' : 'automatisch'}</>
      ),
    },
  ];

  return !isLoading && accountList && fiscalYear ? (
    <>
      <div>
        <Table
          data={accountList}
          columns={columns}
          export={true}
          noDataText='Keine Konten in diesem Haushaltsjahr gefunden.'
          disabled={false}
          isSearchable={true}
          headerContent={<AddAccount fiscalYear={fiscalYear} />}
          headline={'Konten'}
        />
      </div>
    </>
  ) : !fiscalYear ? (
    <div>Kein Haushaltsjahr ausgewählt, bitte auswählen.</div>
  ) : (
    <div>Konten werden geladen</div>
  );
};

export default Accounts;
