import { fubApi } from './index';

const fiscalYearApi = fubApi.injectEndpoints({
  endpoints: (builder) => ({
    listFiscalYears: builder.query<any, void>({
      query: () => `/api/v1/fiscal-year`,
      providesTags: [{ type: 'FiscalYearList', id: 'LIST' }],
    }),
    createFiscalYear: builder.mutation<any, any>({
      query: (payload) => ({
        url: `api/v1/fiscal-year`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'FiscalYearList', id: 'LIST' }],
    }),
    updateFiscalYear: builder.mutation<any, any>({
      query: ({ id, payload }) => ({
        url: `api/v1/fiscal-year/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'FiscalYearList', id: 'LIST' }],
    }),
  }),
});

export const {
  useListFiscalYearsQuery,
  useCreateFiscalYearMutation,
  useUpdateFiscalYearMutation,
} = fiscalYearApi;
