import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import datenschutzMd from './../../assets/md/datenschutz.md';

const DataPrivacyPage = () => {
  const [dataPrivacyText, setDataPrivacyText] = useState(null);

  useEffect(() => {
    if (!dataPrivacyText) {
      fetch(datenschutzMd)
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          setDataPrivacyText(text);
        });
    }
  }, []);

  return (
    <div>
      <div className='card text-start'>
        <div className='card-body'>
          <p className='card-text'>
            <ReactMarkdown children={dataPrivacyText} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default DataPrivacyPage;
