import React, { FormEvent, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ApplicationForm from './ApplicationForm';
import {
  useCreateApplicationMutation,
  useListApplicationCategoriesQuery,
} from '../../api/applicationApi';

const AddApplication = ({ fiscalYear }) => {
  const [createApplication, { isLoading }] = useCreateApplicationMutation();
  const { data: categoryList } = useListApplicationCategoriesQuery();
  const [errors, setErrors] = useState(null);
  const [open, setOpen] = useState(false);

  const defaultFormValues = {
    title: '',
    description: '',
    issuer: '',
    date: '',
    amount: '',
    category: '',
    status: 'REQUESTED',
    fiscalYear: fiscalYear?.id,
  };

  useEffect(() => {
    if (categoryList) {
      setCategories(categoryList.map((cat) => cat.name));
    }
  }, [categoryList]);

  const [formValues, setFormValues] = useState(defaultFormValues);
  const [categories, setCategories] = useState([]);

  const setValue = (prop, value) => {
    setFormValues((v) => ({
      ...v,
      [prop]: value,
    }));
  };

  const handleOpen = () => {
    setErrors(null);
    setFormValues(defaultFormValues);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setErrors(null);
    createApplication({
      ...formValues,
      amount: parseFloat(formValues.amount),
    })
      .unwrap()
      .then(() => {
        toast.success('Antrag erfolgreich angelegt.');
        handleClose();
      })
      .catch((error) => setErrors(error?.data?.message));
  };

  return (
    <>
      <button className='btn btn-lg px-5 btn-primary' onClick={handleOpen}>
        Neuen Antrag erstellen
      </button>
      <Modal size='lg' show={open} backdrop='static' onHide={handleClose}>
        <Modal.Header>
          <h4>Antrag erstellen</h4>
        </Modal.Header>
        <Modal.Body>
          <ApplicationForm
            categoryOptions={categories}
            formValues={formValues}
            setValue={setValue}
            errors={errors}
            onSubmit={handleSubmit}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={handleClose}
          >
            Abbrechen
          </button>
          <button
            type='submit'
            className='btn btn-success'
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Speichern
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddApplication;
